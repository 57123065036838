import React from "react";
import PropTypes from "prop-types";
import Layout from "../../../../layouts/Layout";
import Seo from "../../../seo";
import Intro from "../../../common/Intro";
import Breadcrumb from "../../../common/Breadcrumb";
import BasicSection from "../../../common/BasicSection";
import ITLegalAssistanceServices from "./ITLegalAssistanceServices";
import { getHrefs } from "../../../../utils/langService";
import { getShareImage } from "../../../../utils/getShareImage";

import imgLawyer from "../../../../images/lawyer.jpg";

const ITLegalAssistance = props => {
  const { lang, t, layoutT, servicesT, postsT } = props.pageContext;
  const { pathname } = props.location;

  const { pages } = getHrefs(lang);

  return (
    <Layout t={layoutT} servicesT={servicesT} posts={postsT} lang={lang} pathname={pathname}>
      <Seo title={t.seo.title} lang={lang} description={t.seo.description} image={getShareImage(lang)} />

      <Intro title={t.intro.title} />

      <Breadcrumb lang={lang} actualPage={t.seo.title} pages={[{ name: servicesT.additional.title }]} />

      <div className="AdditionalServices">
        <BasicSection t={t.mainSection} image={imgLawyer} link={`/${lang}/${pages[3]}/`} />

        <ITLegalAssistanceServices t={t.services} />
      </div>
    </Layout>
  );
};

ITLegalAssistance.propTypes = {
  pageContext: PropTypes.object.isRequired
};

export default ITLegalAssistance;
