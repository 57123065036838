import React from "react";
import PropTypes from "prop-types";

const ITLegalAssistanceServices = ({ t }) => (
  <section className="ITLegalAssistance__services">
    <div className="container">
      {t.map((elem, index) => (
        <div key={index} className="ITLegalAssistance__services__item">
          <h4 className="title ITLegalAssistance__services__title">{elem.title}</h4>

          <div className="ITLegalAssistance__services__item__container">
            <div className="ITLegalAssistance__services__item__left">
              <p>{elem.description}</p>
            </div>

            <div className="ITLegalAssistance__services__item__right">
              <p>{elem.listTitle}</p>
              <ul className="list-circle list-circle--primary">
                {elem.list.map((elem, i) => (
                  <li key={i}>{elem}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ))}
    </div>
  </section>
);

ITLegalAssistanceServices.propTypes = {
  t: PropTypes.array.isRequired
};

export default ITLegalAssistanceServices;
